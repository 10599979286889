import { Injectable } from '@angular/core';
import * as jwt from "jsonwebtoken";
import * as crypto from 'crypto-browserify'
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { Observable,BehaviorSubject, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { EmitService } from '@app/ts/services/emit.service';
declare var Buffer: any;
@Injectable({
  providedIn: 'root'
})
export class CommonService {

  pvtkey;
  linkCode = "";
  private localKeys = {
    "language": "language",
    "gender": "gender",
    "chatInfo": "chatInfo",
    "countries": "countries",
    "packages": "packages",
    "dateFormat": "dateFormat"
  };
  public profilemImageChange = new BehaviorSubject < boolean > (false);
  profilemImageChangeObservable = this.profilemImageChange.asObservable();

  public profileDeatilsChange = new BehaviorSubject < boolean > (false);
  userDataChangeObservable = this.profileDeatilsChange.asObservable();
  whiteLabelImage: any;
  constructor(private httpClient: HttpClient, private emitService: EmitService) {}
  createObject(data) {
    var object = {}
    if (!Array.prototype.forEach) {
      Array.prototype.forEach = function (fn, scope) {
        for (var i = 0, len = this.length; i < len; ++i) {
          fn.call(scope, this[i], i, this);
        }
      }
    }
    data.forEach(function (value, key) {
      object[key] = value;
    });
    object['rolePartyId'] = localStorage.getEncryptedItem('partyID');
    object['roleAccId'] = localStorage.getEncryptedItem('accId');
    return object;
  }
  createToken(data) {
    let cert;
    let privateKey = environment.privateKey.key;
    let key = '';
    let begin = '-----BEGIN RSA PRIVATE KEY-----';
    let end = '-----END RSA PRIVATE KEY-----';
    while (privateKey.length > 0) {
      key += privateKey.substring(0, 64) + '\n';
      privateKey = privateKey.substring(64);
    }
    cert = `${begin}\n${key}${end}\n`;
    let paramsOption = {
      algorithm: 'RS256',
      expiresIn: 120
    };
    let token = jwt.sign(data, cert, paramsOption);
    this.setCookie(this.encrypt(environment.USER_ACTIVE_CHECK), this.encrypt(new Date().toString()), 10000, environment.cookieDomain, '/')
    return token;
  }
  encrypt(data) {

    var binaryEncryptionKey = new Buffer(environment.encryptionKey, "base64");
    var binaryIV = new Buffer(0);

    var cipher = crypto.createCipheriv("AES-128-ECB", binaryEncryptionKey, binaryIV);
    var encryptedInput = (
      cipher.update(data, "utf8", "base64") +
      cipher.final("base64")
    );
    return encryptedInput;
  }

  decrypt(data) {
    try {


      var binaryEncryptionKey = new Buffer(environment.encryptionKey, "base64");
      var binaryIV = new Buffer(0);

      var decipher = crypto.createDecipheriv("AES-128-ECB", binaryEncryptionKey, binaryIV);
      var decryptedInput = (
        decipher.update(data, "base64", "utf8") +
        decipher.final("utf8")
      );
      return decryptedInput;
    } catch (error) {

      return '';
    }
  }
  getCurrentLanguageCode() {
    let currentLang = localStorage.getEncryptedItem("current_lang");
    let lang = "de";
    if (currentLang) {
      currentLang = JSON.parse(currentLang);
      lang = currentLang["key"];
    }
    return lang;
  }
  fetchLanguage(language) {
    let langData = this.getCommonDataOnLanuguage(language, this.localKeys.language);
    if (!langData || this.getCurrentLanguageCode() != language) {
      var formData = new FormData();
      formData.append('userId', '');
      formData.append("loggedInTime", '');
      formData.append("lastRequestTime", '');
      return this.httpClient.get("assets/api/languages.json").pipe(map(res => {
        this.setCommonDataOnLanguage(language, this.localKeys.language, res["DATA"]);
        return res["DATA"];
      }));
    } else {
      return new Observable((observable) => {
        observable.next(langData);
        observable.complete();
      });
    }
  }
  getCommonDataOnLanuguage(language, key) {
    let commonData = localStorage.getEncryptedItem("common-data");
    let retData = null;
    if (commonData) {
      try {
        commonData = JSON.parse(commonData);
        let commonLangData = commonData[language];
        if (commonLangData && commonLangData[key]) {
          retData = commonLangData[key];
        }
      } catch (e) {
        retData = null;
        throw "Error in parsing commondata from localStorage";
      } finally {}
    }
    return retData;
  }
  setCommonDataOnLanguage(language, key, data) {
    let commonData: any = localStorage.getEncryptedItem("common-data");
    let langData;
    if (commonData) {
      commonData = JSON.parse(commonData);
      commonData[language] = commonData[language] || {};
      langData = commonData[language];
    } else {
      commonData = {};
      commonData[language] = {};
      langData = commonData[language];
    }
    langData[key] = data;
    localStorage.setEncryptedItem("common-data", JSON.stringify(commonData));
  }
  setLinkdInCode(LinkdInCode) {
    this.linkCode = LinkdInCode;
    if (this.linkCode != undefined) {
      return this.linkCode;
    } else {
      return "";
    }
  }
  changeDialogState(value: boolean) {
    this.profilemImageChange.next(value);
    //  this. profileDeatilsChange.next(value);
  }

  getCookie(cname) {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  clearExpireCookie() {
    localStorage.clear();
    (() => {
      let cookies = document.cookie.split("; ");
      for (let c = 0; c < cookies.length; c++) {
        if (cookies[c].split(";")[0].split("=")[0] == environment.checkCookie) {
          let d = window.location.hostname.split(".");
          while (d.length > 0) {
            let cookieBase = encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + d.join('.') + ' ;path=';
            let p = location.pathname.split('/');
            document.cookie = cookieBase + '/';
            while (p.length > 0) {
              document.cookie = cookieBase + p.join('/');
              p.pop();
            };
            d.shift();
          }
        }
      }
    })();
  }
  clearCookieStorage(hotReload = false) {
    let accounts_url = localStorage.getEncryptedItem('ACCOUNTS_URL');
    let accounts_api_url = localStorage.getEncryptedItem('API_END_POINT_ACCOUNTS');
    localStorage.clear();
    localStorage.setEncryptedItem('ACCOUNTS_URL',accounts_url);
    localStorage.setEncryptedItem('API_END_POINT_ACCOUNTS',accounts_api_url);
    const host = window.location.host
    const parts = host.split(".");
    parts.shift(); 
    const result = parts.join(".");
    localStorage.setEncryptedItem('domain',result);
    (() => {
      let cookies = document.cookie.split("; ");
      for (let c = 0; c < cookies.length; c++) {
        if (cookies[c].split(";")[0].split("=")[0] !== environment.mtc_cookie) {
          let d = window.location.hostname.split(".");
          while (d.length > 0) {
            let cookieBase = encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + d.join('.') + ' ;path=';
            let p = location.pathname.split('/');
            document.cookie = cookieBase + '/';
            while (p.length > 0) {
              document.cookie = cookieBase + p.join('/');
              p.pop();
            };
            d.shift();
          }
        }
      }
    })();
    if (hotReload)
      window.location.reload();
  }
  setCookie(name: string, value: any, expireDays: number, domain: string, path: string = '') {
    let d: Date = new Date();
    d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
    let expires: string = `expires=${d.toUTCString()}`;
    let cpath: string = path ? `; path=${path}` : '';
    document.cookie = `${name}=${value};domain=${localStorage.getEncryptedItem('domain')}; ${expires}${cpath};`;
  }
  getWhiteLabelConfig(domain: any) {
    let formData = new FormData();
    formData.append('domain', domain);
    let object = this.createObject(formData);
    let token = this.createToken(object);
    let encryptedToken = this.encrypt(token);
    let encrData = new FormData();
    encrData.append('encrToken', encryptedToken);
    // Get API endpoint from local storage
    
    const apiEndpoint = localStorage.getEncryptedItem('API_END_POINT_ACCOUNTS');
    // Make HTTP POST request
    this.httpClient.post(apiEndpoint + 'auth/getWhiteLabelConfig', encrData).pipe(
      map((data: any) => {
        if (data.status) {
          // Successful response handling
          const config = data['data']['configdata'];

          // Store config data in local storage
          localStorage.setEncryptedItem('configData', JSON.stringify(config));
          this.setWhiteLabelImageData(data['data']['imageData']);
          
          // Store other endpoint configurations
          localStorage.setEncryptedItem('API_END_POINT_ACCOUNTS', config['accounts_api_url']);
          localStorage.setEncryptedItem('API_END_POINT_ADMIN', config['admin_api_url']);
          localStorage.setEncryptedItem('API_END_POINT_GATE2B', config['gate2b_api_url']);
          localStorage.setEncryptedItem('domain', config['domain']);
          localStorage.setEncryptedItem('ACCOUNTS_URL', config['accounts_url']);
          localStorage.setEncryptedItem('ADMIN_URL', config['admin_url']);
          localStorage.setEncryptedItem('GATE2B_URL', config['gate2b_url']);
          
          // Emit updated configuration
          this.emitService.setWhiteLabelConfig(config);
        }
      }),
      catchError((error) => {
        console.error('API call failed:', error);
        return of(null); // Return an observable with null
      })
    ).subscribe(response => {
      // You can handle the response here if needed
      if (response) {

      }
    });
  }

  setWhiteLabelImageData(data){
    this.whiteLabelImage = data;
  }
  getWhiteLabelImageData(image_type){
    return this.whiteLabelImage[image_type];
  }
}